// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyDE3IWB69yP9NdNvXL0Nf0Px4coWM-Nuxk",
    authDomain: "stripedemo-b1a50.firebaseapp.com",
    databaseURL: "https://stripedemo-b1a50.firebaseio.com",
    projectId: "stripedemo-b1a50",
    storageBucket: "stripedemo-b1a50.appspot.com",
    messagingSenderId: "653693115691",
    appId: "1:653693115691:web:bdfa6ab259454158"
  },
  stripeKey: 'pk_test_qRiyKL296EEjmHfzktPmQFfG00VsaZP1Xh'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
